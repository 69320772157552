import React, { useState, useEffect, useRef } from 'react';
import './Ceo.css';
import { Link } from 'react-router-dom';
import { split } from "../../Animations/Text";
import gsap from 'gsap';

function Ceo() {
  const imgContainerRef = useRef(null); // Define the ref here

  useEffect(() => {
    split(); // Initialize split text animation
    
    const imgContainer = imgContainerRef.current;

    // GSAP animation for the image on scroll
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          gsap.to(imgContainer.querySelector('img'), {
            y: 0,
            duration: 1,
            ease: "power2.out",
          });
        }
      });
    }, { threshold: 0.1 });

    if (imgContainer) {
      observer.observe(imgContainer);
    }

    return () => {
      if (imgContainer) {
        observer.unobserve(imgContainer);
      }
    };
  }, []);

  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const handleNextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === slideTexts.length - 1 ? 0 : prevSlide + 1));
  };

  const slideTexts = [
    "If you are not doing the intrinsic work, no matter how far you go, the things you fail to work on will collapse you.",
    "It is not about convenience when it comes to building or leading a great life but progression. Focus on making progress.",
    "If you don't take care of things that are truly important, the things that feel urgent wouldn't take care of themselves.",
    "Your character is what preserves you. If you focus on the tree, you will never have to worry about the fruits."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide === slideTexts.length - 1 ? 0 : prevSlide + 1));
    }, 7000);

    return () => clearInterval(interval);
  }, [slideTexts.length]);

  return (
    <div className="ceo">
      <div className="ceo-left" ref={imgContainerRef}>
        <img
          src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623750/20240814_061223_qd76yo.jpg"
          alt="CEO Muyiwa Awoniyi"
        />
      </div>

      <div className="ceo-right">
        <div className="meet">
          <p>MEET THE CEO</p>
          <h2 data-animation='paragraph'>
            MUYIWA <br /> AWONIYI<strong></strong>
          </h2>
          <span className='ceo-brief'>
            A seasoned Nigerian music executive and co-founder of
            BSB Management. A remarkable manager, Muyiwa moved to the UK as a
            teenager to further his education. Originally pursuing a career in
            management, he later shifted to video production. Throughout his
            career, he has managed some of the finest talent to emerge from
            Africa.
          </span>
        </div>

        <div className='quote'>
          <div className="slide-con">
            <p>Top Quotes from Muyiwa</p>
            <div className="slider">
              <div className="slide-text">
                {slideTexts.map((text, index) => (
                  <div
                    key={index}
                    className={`slide ${index === activeSlide ? "active" : ""}`}
                  >
                    <span>{text}</span>
                  </div>
                ))}
              </div>

              <div className="navigation">
                <div className="pagination">
                  {slideTexts.map((_, index) => (
                    <div
                      key={index}
                      className={`slid ${index === activeSlide ? "active" : ""}`}
                      onClick={() => handleSlideChange(index)}
                    />
                  ))}
                </div>

                <div className="eclipse" onClick={handleNextSlide}>
                  <img
                    src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1700718884/icons8-dropdown-50_nfgubs.png"
                    alt="Next Slide"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='media'>
            <p>Wanna get more oil (insights) to lead a good life? Connect with Muyiwa here.</p>

            <div className='media-con'>
              <Link className='media-box' to={'https://www.youtube.com/@InsightsByMuyiwa'} target="_blank">
                <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723788275/icons8-youtube-48_1_mjklak.png' alt='YouTube'></img>
              </Link>

              <Link className='media-box' to={'https://open.spotify.com/show/1ApVxynZLVgxoanxWdY3AQ?si=KewViyt1QLKFbuCkladyGw'} target="_blank">
                <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723788274/icons8-spotify-48_nlqm9n.png' alt='Spotify'></img>
              </Link>

              <Link className='media-box' to={'https://x.com/IAmDonawon'} target="_blank">
                <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723788275/icons8-x-50_zjyoen.png' alt='Twitter'></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ceo;
