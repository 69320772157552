import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { split } from "../../Animations/Text";
import { gsap } from "gsap";
import { IO } from "../../Animations/Observe";
import './About.css';

function About() {
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        split();

        const videoElement = videoRef.current;
        const animateVideo = () => {
            gsap.fromTo(
                videoElement,
                { y: 500, opacity: 0 },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.2,
                    ease: "power3.out",
                }
            );
        };

        IO(videoElement, { threshold: 0.5 }).then(() => {
            animateVideo();
        });

        animateVideo();
    }, []);

    const toggleSound = () => {
        const videoElement = videoRef.current;
        videoElement.muted = !videoElement.muted;
        setIsMuted(videoElement.muted);
    };

    return (
        <div className='about'>
            <Helmet>
                <title>About Us - BSB Management</title>
                <meta name="description" content="BSB Management - A top-tier management agency specializing in music, film, and business talent." />
                <meta property="og:title" content="About Us - BSB Management" />
                <meta property="og:description" content="Learn about BSB Management's dedication to supporting talent in music, film, and business." />
                <meta property="og:image" content="https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623769/20240814_060240_krwnzg.jpg" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="BSB Management, talent agency, music, film, business, talent management" />
            </Helmet>

            <div className="talent-profile">
                <h3 data-animation='paragraph'>ABOUT US</h3>
            </div>

            <div className='about-cta'>
                <div className='join-text'>
                    <p>At BSB Management, we are a top-tier management agency specializing in nurturing and representing the best talent in music, film, and business. With a focus on providing personalized, strategic guidance, we empower our exclusive roster of artists and producers to thrive creatively and commercially.</p>
                </div>

                <div className='join-cta'>
                    <button>Join Our Team</button>
                </div>
            </div>

            <div className="insight">
                <video
                    ref={videoRef}
                    className="insight-2022"
                    autoPlay
                    loop
                    muted={isMuted}
                    playsInline
                    src="https://res.cloudinary.com/dxnukbo0u/video/upload/v1726554368/ssstwitter.com_1726554286830_tz9db5.mp4"
                ></video>

                <button onClick={toggleSound}>
                    {isMuted ? 'Tap for sound' : 'Sound on'}
                </button>
            </div>

            <div className='oiling'>
                <h2 data-animation='header'>WE ARE OILING!</h2>
                <p> Our hands-on approach ensures that every client’s vision is realized while maintaining a balance of artistic integrity and business success. <br /><br /> We're passionate about cultivating lasting partnerships and delivering excellence in every aspect of talent management.</p>
            </div>

            <div className="team-con">
                <h2 data-animation='header'>MEET THE TEAM</h2>

                <div className='team'>
                    <div className='team-box'>
                        <p>MUYIWA AWONIYI</p>
                        <span>CEO</span>
                        <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623750/20240814_061223_qd76yo.jpg' alt='ceo'></img>
                    </div>

                    <div className='team-box'>
                        <p>ABIMBOLA IBIROGBA</p>
                        <span>EXECUTIVE ASSISTANT</span>
                        <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1727009138/IMG_8149_ne1wpd.jpg' alt='EA'></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
