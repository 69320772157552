import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import './Brief.css';

gsap.registerPlugin(CSSRulePlugin);

function Brief() {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const imgContainer = imgContainerRef.current;
    const img = imgContainer.querySelector("img");
    const overlayRule = CSSRulePlugin.getRule(".img-container:after");

    // Define the timeline with smoother easing
    const tl = gsap.timeline({ paused: true });
    
    tl.set(img, { opacity: 0, scale: 1.3 }) // Start with a zoomed-in and hidden image
      .to(overlayRule, { width: "100%", duration: 0.8, ease: "power2.out" })
      .to(overlayRule, { width: "0%", duration: 0.8, ease: "power2.in" })
      .to(img, { opacity: 1, scale: 1, duration: 1, ease: "power2.out" }); // Smoothly reveal image with scale animation

    // IntersectionObserver to trigger animation when in viewport
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            tl.play();
            observer.unobserve(imgContainer); // Stop observing after playing the animation once
          }
        });
      },
      { threshold: 0.6 } // Adjust threshold to trigger only when 60% of imgContainer is visible
    );

    if (imgContainer) observer.observe(imgContainer);

    // Cleanup function
    return () => observer.disconnect();
  }, []);

  return (
    <div className='brief'>
      <div className='brief-left'>
        <h2 data-animation='paragraph'>WHAT IS BSB MANAGEMENT REALLY ABOUT?</h2>
        <p>We pride ourselves on representing some of the most dynamic talents and groundbreaking projects in the industry. Our expertise spans across music, film, and business management, where we guide our clients towards achieving their fullest potential and securing their place at the top of their fields.</p>
      </div>

      <div className='brief-right' ref={imgContainerRef}>
        <div className="img-container">
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1727845952/PHOTO-2024-09-27-15-50-30_gp8jwo.jpg' alt='insight_BSB'></img>
        </div>
      </div>
    </div>
  );
}

export default Brief;
