import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import ScrollTop from './Components/Scrolltop'; 
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Booking from './Pages/Booking/Booking';
import Portfolio from './Pages/Portfolio/Portfolio';

import './App.css';
import { split } from "./Animations/Text"; 
import scroll from "./Animations/Scroll";

scroll(); // Initialize scroll animations

const Layout = () => {
  useEffect(() => {
    split(); // Initialize split text animation
  }, []);

  return (
    <div className="main">
      <ScrollTop />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/booking", element: <Booking /> },
      { path: "/portfolio", element: <Portfolio /> },
    ]
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} /> {/* Show main content */}
    </div>
  );
}

export default App;

