import React, { useEffect } from "react";
import { split } from "../../Animations/Text";
import './Music.css';

function Music() {
  useEffect(() => {
    split(); // Initialize split text animation
  }, []);

  return (
    <div className='Music'>
       <div className='music'>
         <div className='music-head'>
         <h2 data-animation='paragraph'>MUSIC</h2>
         </div>

          <div className='music-con'>
            <div className='music-box' id='first-box'>
                <p>ARTISTE DEVELOPMENT</p>
                <span>We have successfully nurtured and launched the careers of several chart-topping artists. Our approach combines creative strategy, branding, and long-term career planning to ensure sustained success in an ever-evolving industry.</span>
            </div>

            <div className='music-box' id='mid-box'>
                <p>TOUR MANAGEMENT</p>
                <span>From intimate venues to global arenas, our tour management services guarantee seamless operations and unforgettable experiences for both artists and fans. We handle everything from logistics to promotion, ensuring our artists shine on every stage.</span>
            </div>

            <div className='music-box' id='last-box'>
                <p>MUSIC PRODUCTION</p>
                <span>Collaborating with top producers and songwriters, we’ve produced award-winning albums that have resonated with audiences worldwide. Our in-house team ensures that every project meets the highest industry standards.</span>
            </div>
          </div>
       </div>

       <div className='music'>
         <div className='music-head'>
         <h2 data-animation='paragraph'>FILM</h2>
         </div>

          <div className='music-con'>
            <div className='music-box' id='first-box'>
                <p>TALENT REPRESENTATION</p>
                <span>We represent a diverse roster of actors, directors, and screenwriters, securing roles and opportunities that align with their unique talents and career goals. Our clients have starred in critically acclaimed films and blockbuster hits..</span>
            </div>

            <div className='music-box' id='mid-box'>
                <p>FILM PRODUCTION</p>
                <span>From script to screen, we manage all aspects of film production, ensuring that each project is delivered on time, within budget, and with uncompromised quality. Our films have premiered at major festivals and received numerous accolades.</span>
            </div>

            <div className='music-box' id='last-box'>
                <p>PROJECT FINANCING</p>
                <span>Our expertise in securing funding and managing budgets has made us a trusted partner in the film industry. We work closely with investors to bring compelling stories to life, ensuring financial success for all parties involved.</span>
            </div>
          </div>
       </div>

       <div className='music'>
         <div className='music-head'>
         <h2 data-animation='paragraph'>BUSINESS</h2>
         </div>

          <div className='music-con'>
            <div className='music-box' id='first-box'>
                <p>BRAND DEVELOPMENT</p>
                <span>We craft strong, recognizable brands that resonate with audiences and drive business growth. Our strategic approach includes market analysis, branding, and marketing campaigns that set our clients apart from the competition.</span>
            </div>

            <div className='music-box' id='mid-box'>
                <p>CORPORATE CONSULTING</p>
                <span>With our deep industry knowledge and vast network, we provide consultancy services that help businesses navigate challenges and seize opportunities. We’ve helped companies across various industries achieve significant milestones and expand their market presence.</span>
            </div>

            <div className='music-box' id='last-box'>
                <p>EXECUTIVE COACHING</p>
                <span>We offer personalized coaching for business leaders, empowering them to lead with confidence and make strategic decisions that drive their organizations forward. Our clients include top executives and entrepreneurs who value our insight and guidance.</span>
            </div>
          </div>
       </div>
    </div>
  )
}

export default Music