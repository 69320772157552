import React from 'react'
import Hero from '../../Components/Hero/Hero'
import Brief from '../../Components/Brief/Brief'
import Music from '../../Components/Music/Music'
import Port from '../../Components/Port/Port'
import Ceo from '../../Components/Ceo/Ceo'
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
       <Helmet>
        <title>Home - Top Music, Film & Business Management Agency </title>
        <meta
          name="description"
          content="."
        />
      </Helmet>
      <Hero />
      <Brief />
      <Music />
      <Port />
      <Ceo />
    </div>
  )
}

export default Home