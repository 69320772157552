import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { IO } from "../../Animations/Observe"; // Adjust path if necessary
import './Port.css';
import { split } from "../../Animations/Text";

function Port() {
  const [scrollDirection, setScrollDirection] = useState('normal');
  const portReelRef = useRef(null);

  useEffect(() => {
    split(); // Initialize split text animation

    // Get the port-reel element
    const portReelElement = portReelRef.current;

    // Initially hide the port-reel
    gsap.set(portReelElement, { opacity: 0 });

    const animatePortBoxes = () => {
      gsap.to(portReelElement, { opacity: 1, duration: 0.5 }); // Fade in the port-reel

      gsap.fromTo(
        portReelElement.querySelectorAll('.port-box'),
        { y: 50, opacity: 0 }, // Starting state
        {
          y: 0, // Ending state
          opacity: 1,
          duration: 1.2,
          stagger: 0.6, // Delay between each item
          ease: "power3.out",
        }
      );
    };

    // Use IntersectionObserver to trigger animation when element enters the viewport
    IO(portReelElement, { threshold: 0.5 }).then(() => {
      animatePortBoxes();
    });

    // Scroll direction handling
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollDirection('normal');
      } else {
        setScrollDirection('reverse');
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='port'>
      <div className='port-con'>
        <p>OUR PORTFOLIO</p>
        <h2 data-animation='paragraph'>TALENTS MANAGED</h2>
        <h2 data-animation='paragraph' id='no-manage'>& MANAGING</h2>
      </div>

      <div
        className={`port-reel ${scrollDirection === 'reverse' ? 'reverse' : ''}`}
        ref={portReelRef}
      >
        <div className='port-box'>
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623767/20240814_080152_kieoiz.jpg' alt='acts' />
          <span>RECORDING ARTISTE</span>
          <p>Nonso Amadi(Past)</p>
        </div>

        <div className='port-box'>
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623766/20240814_080251_le4hc0.jpg' alt='acts' />
          <span>RECORDING ARTISTE</span>
          <p>Tems(Current)</p>
        </div>

        <div className='port-box'>
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623766/20240814_080353_udrnjx.jpg' alt='acts' />
          <span>RECORDING ARTISTE</span>
          <p>Omah Lay(Current)</p>
        </div>

        <div className='port-box'>
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623768/20240814_080459_m942vs.jpg' alt='acts' />
          <span>MUSIC PRODUCER</span>
          <p>Lekaa Beats(Current)</p>
        </div>
      </div>
    </div>
  );
}

export default Port;
