import React, { useState, useEffect } from 'react';
import './Footer.css';
import { split } from "../../Animations/Text";

function Footer() {
  useEffect(() => {
    split(); // Initialize split text animation
  }, []);
  
  const currentYear = new Date().getFullYear();
  
  const [londonTime, setLondonTime] = useState('');

  // Function to update London time
  const updateLondonTime = () => {
    const timeInLondon = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Europe/London',
    }).format(new Date());
    
    setLondonTime(timeInLondon);
  };

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(updateLondonTime, 1000);
    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <div className='footer'>
      <div className='footer-header'>
       <h2 data-animation='paragraph'>BELIEVE.</h2>
       <h2 data-animation='paragraph'>STRIVE. BECOME.</h2>
       <p>All rights reserved.</p>
      </div>

      <div className='footer-btn'>
        {/* Use <a> instead of Link for mailto */}
        <a href="mailto:bsbinsights@gmail.com" target="_blank" rel="noopener noreferrer">
          <button>GET IN TOUCH</button>
        </a>
      </div>

      <div className='footer-con'>
        <div className='moment'>
          <div>
            <span>COPYRIGHT</span>
            <p>© {currentYear} BSB MANAGEMENT.</p>
          </div>

          <div>
            <span>TIME (LONDON, UK)</span>
            <p>{londonTime}</p> {/* Dynamic London time */}
          </div>
        </div>

        <div className='socials'>
          <span>SOCIALS</span>

          <div>
            {/* Use <a> tags for external links */}
            <a href="https://www.instagram.com/bsbtheagency/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
            <a href="https://x.com/BSB_Management" target="_blank" rel="noopener noreferrer">TWITTER</a>
            <a href="https://www.linkedin.com/company/bsb-management-agency/" target="_blank" rel="noopener noreferrer">LINKEDIN</a>
            <a href="https://www.youtube.com/@InsightsByMuyiwa" target="_blank" rel="noopener noreferrer">YOUTUBE</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

