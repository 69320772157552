import React from 'react'
import './Booking.css';
import { Link } from 'react-router-dom';

function Booking() {
  return (
    <div className='booking'>
        <div className='booking-con'>
            <span>BSB<br></br>MANAGEMENT</span>
            <label>Email Address</label>
            <input type='email' placeholder='Enter Email Address'></input>

            <label>Select Date</label>
            <input type='date'  placeholder=''></input>

            <button>BOOK SESSION</button>
            <Link to={'/'}>Cancel</Link>
        </div>
    </div>
  )
}

export default Booking